export const mainMenu = {
	dashboard: {
		id: 'dashboard',
		text: 'Home',
		path: '/',
		icon: 'Home',
		isDisable: false,
		subMenu: null,
	},
	leagueSetup: {
		id: 'leagueSetup', // rif
		text: 'Setup Lega',
		path: 'setup-lega',
		icon: 'Build',
		isDisable: false,
		subMenu: null,
	},
	listaCalciatori: {
		id: 'listaCalciatori',
		text: 'Lista Calciatori',
		path: 'lista-calciatori',
		icon: 'People',
		isDisable: false,
		subMenu: null,
	},
	asta: {
		id: 'asta',
		text: 'Aste',
		path: 'asta',
		icon: 'Gavel',
		isDisable: false,
		subMenu: null,
	},
	// asta2: {
	// 	id: 'astaAttiva',
	// 	text: 'Asta In Corso',
	// 	path: 'attiva',
	// 	icon: 'Event',
	// 	isDisable: false,
	// 	subMenu: null,
	// },
	riepilogoSquadre: {
		id: 'riepilogoSquadre',
		text: 'Riepilogo Squadre',
		path: 'riepilogo',
		icon: 'FormatListNumberedRtl',
		isDisable: false,
		subMenu: null,
	},
	campionato: {
		id: 'campionato',
		text: 'Campionato',
		path: 'campionato',
		icon: 'SportsSoccer',
		isDisable: false,
		subMenu: null,
	},
	dubbione: {
		id: 'dubbione',
		text: 'Dubbione AI',
		icon: 'HowToReg',
		path: 'dubbione',
		isDisable: true,
		subMenu: null,
	},
};

export const upgradeMenu = {
	update: {
		id: 'update',
		text: 'Upgrade',
		icon: 'Extension',
		path: 'upgrade',
	},

	// login: {
	// 	id: 'login',
	// 	text: 'Login',
	// 	path: 'auth-pages/login',
	// 	icon: 'Login',
	// },
	// signUp: {
	// 	id: 'signUp',
	// 	text: 'Sign Up',
	// 	path: 'auth-pages/sign-up',
	// 	icon: 'PersonAdd',
	// },
	// page404: {
	// 	id: 'Page404',
	// 	text: '404 Page',
	// 	path: 'auth-pages/404',
	// 	icon: 'ReportGmailerrorred',
	// },
};

export const footerMenu = {
	update: {
		id: 'update',
		text: 'Upgrade',
		icon: 'Upgrade',
		path: 'upgrade',
		isDisable: false,
		subMenu: null,
		outline: true,
	},
	profile: {
		id: 'profile',
		text: 'Profilo',
		path: 'profilo',
		icon: 'PersonOutline',
		isDisable: false,
		subMenu: null,
	},
};

export const pageLayoutTypesPagesMenu = {
	prossimeFunzionalita: {
		id: 'prossimeFunzionalita',
		text: 'Prossime Funzionalità',
	},
	// blank: {
	// 	id: 'blank',
	// 	text: 'Blank',
	// 	path: 'page-layouts/blank',
	// 	icon: 'check_box_outline_blank ',
	// },
	// pageLayout: {
	// 	id: 'pageLayout',
	// 	text: 'Page Layout',
	// 	path: 'page-layouts',
	// 	icon: 'BackupTable',
	// 	subMenu: {
	// 		headerAndSubheader: {
	// 			id: 'headerAndSubheader',
	// 			text: 'Header & Subheader',
	// 			path: 'page-layouts/header-and-subheader',
	// 			icon: 'ViewAgenda',
	// 		},
	// 		onlyHeader: {
	// 			id: 'onlyHeader',
	// 			text: 'Only Header',
	// 			path: 'page-layouts/only-header',
	// 			icon: 'ViewStream',
	// 		},
	// 		onlySubheader: {
	// 			id: 'onlySubheader',
	// 			text: 'Only Subheader',
	// 			path: 'page-layouts/only-subheader',
	// 			icon: 'ViewStream',
	// 		},
	// 		onlyContent: {
	// 			id: 'onlyContent',
	// 			text: 'Only Content',
	// 			path: 'page-layouts/only-content',
	// 			icon: 'WebAsset',
	// 		},
	// 	},
	// },
	// asideTypes: {
	// 	id: 'asideTypes',
	// 	text: 'Aside Types',
	// 	path: 'aside-types',
	// 	icon: 'Vertical Split',
	// 	subMenu: {
	// 		defaultAside: {
	// 			id: 'defaultAside',
	// 			text: 'Default Aside',
	// 			path: 'aside-types/default-aside',
	// 			icon: 'ViewQuilt',
	// 		},
	// 		minimizeAside: {
	// 			id: 'minimizeAside',
	// 			text: 'Minimize Aside',
	// 			path: 'aside-types/minimize-aside',
	// 			icon: 'View Compact',
	// 		},
	// 	},
	// },
};

export const loginMenu = {
	login: {
		id: 'login',
		text: 'Login',
		path: 'login',
		// icon: 'Login',
	},
};
export const paymentMenu = {
	positive: {
		id: 'positive',
		text: 'Positive',
		path: 'pagamento-riuscito',
		// icon: 'Login',
	},
	negative: {
		id: 'negative',
		text: 'Negative',
		path: 'pagamento-fallito',
		// icon: 'Login',
	},
};

export const productsExampleMenu = {
	companyA: { id: 'companyA', text: 'Company A', path: 'grid-pages/products', subMenu: null },
	companyB: { id: 'companyB', text: 'Company B', path: '/', subMenu: null },
	companyC: { id: 'companyC', text: 'Company C', path: '/', subMenu: null },
	companyD: { id: 'companyD', text: 'Company D', path: '/', subMenu: null },
};
